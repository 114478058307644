<template>
  <div class="content admin flex flex-col gap-2">
    <button-select
      v-model="page"
      :options="pages"
      @input="onPageSelect"
    />

    <div class="mt-3">
      <user-accounts v-if="page==='Accounts'" />
      <user-roles v-if="page==='Roles'" />
      <user-groups v-if="page==='Groups'" />
    </div>
  </div>
</template>

<script>
const ButtonSelect = () => import('@/components/ButtonSelect.vue');
const UserAccounts = () => import('@/components/admin/UserAccounts.vue');
const UserRoles = () => import('@/components/admin/UserRoles.vue');
const UserGroups = () => import('@/components/admin/UserGroups.vue');

export default {
  name: 'Admin',
  components: {
    ButtonSelect,
    UserAccounts,
    UserRoles,
    UserGroups,
  },
  data() {
    return {
      page: this.$route.query.page ?? 'Accounts',
      pages: [
        { text: 'Accounts', value: 'Accounts' },
        { text: 'Roles', value: 'Roles' },
        { text: 'Groups', value: 'Groups' },
      ],
    };
  },
  methods: {
    setPage(page) {
      this.page = page;
      this.$router.replace({ query: { page } });
    },
    onPageSelect(page) {
      this.setPage(page);
    },
  },
  created() {
    // no-op
  },
};
</script>

<style lang="scss" scoped>
  .admin {
    max-width: 900px;
  }
</style>
